import { Box, Button, CardMedia, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import agua from '../../image/madera.png'
import arbol from '../../image/arbol.png'
import borde from '../../logica/ui/img/border-image-golden.png'
import { misionService } from '../../services/misionService'
import { setInformacion } from '../../store/slices/usuarios'
import { Alerta } from '../ux/Alert'
import dayjs  from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { recolectarSevice } from '../../services/recolectarSevice'
import { tierras } from '../niveles/tierras'
import { SubirNivelButton } from './botones/SubirNivelButton'
import { Construccion } from './Construccion'
import { Contador } from '../comun/Contador'
import { v4 } from 'uuid'

dayjs.extend(utc)
const MyComponent = styled('div')({
  color: 'darkslategray',
  backgroundColor: 'aliceblue',
  padding: 8,
  borderRadius: 4,
});

const Framed = styled('div')({
  borderImageSource: `url(${borde})`,
  borderStyle: 'solid',

  'border-image-repeat': 'repeat',
  'border-image-slice': '4 4 4 4',
  'border-image-width': '18px',
  'border-width': '15px',
  padding: '12px'
  
    // background-clip: padding-box;
    // background-origin: padding-box;
    // background-position: center; 
    // box-sizing: border-box;
    // -moz-box-sizing: border-box;
    // -webkit-box-sizing: border-box;
  
})
export const Arbol = ({selector, handleClose: handleClose2}) => {
  const [semillas, setSemillas] = useState(1)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const dispatch = useDispatch()
  const {informacion  } = useSelector(state=> state.userSlice)
  const handleClose = ()=> setOpen(false)
  const handleChange = (e)=>{
    const cantidad = parseInt(e.target.value)
    if(cantidad > selector?.nivel)return
  
    setSemillas(cantidad)
  }
  const recolectarSemilla = async()=>{
    const { data } = await recolectarSevice(selector?.id)
    dispatch(setInformacion(data))
    setError(false)
    setMensaje('SUCCESS')
    setOpen(true)
  }
  const plantarSemilla = async()=>{
    try {
      const {data} = await misionService(selector?.id,semillas)
      console.log(data)
      dispatch(setInformacion(data))
      setError(false)
      setMensaje('SUCCESS')
      setOpen(true)
    } catch (error) {
      setError(true)
      setOpen(true)
      const msg = error?.response?.data?.error || "ERROR"
      setMensaje(msg)
    }
    
   
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative !important', overflow: 'auto !important', width: '100%', height: '100%'}}>
   <Construccion selector={selector} />
      <Box onClick={handleClose2} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10001, color: 'gray'}}>X</Box>
    <Box  class="rpgui-container framed-golden" sx={{position: 'relative !important'}}>
    <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Inventory:  </Typography>
      <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Wood: {informacion?.inventario.find(i=> i?.item === 'Wood')?.cantidad} </Typography>
      <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Water: {informacion?.inventario.find(i=> i?.item === 'Water')?.cantidad} </Typography>
    </Box>
    <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: {xs: 'column', md: 'row'}, overflow: 'auto !important', width: '100%', height: '100%'}}>


  <Box class="rpgui-container framed-golden-m" sx={{flexGrow: 1,position: 'relative !important',width:{xs: '100% !important', md:'50% !important'}, display: 'flex',  alignItems: 'center',justifyContent: 'center', flexDirection: 'column',height: '70vh' }}>
  <CardMedia
              className="rpgui-cursor-grab-open"
             component="img"
             image={"https://res.cloudinary.com/devuvyuij/image/upload/v1682520600/CITYFARMING/ARBOL/image_3_amwxov.png"}
             sx={{ width: "45%", objectFit: "cover", m: '0 auto' }}
           />
             <Box sx={{width: '100%', height: '60%', display: 'flex',  alignItems: 'center',justifyContent: 'center' }}>
           <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
           <Typography variant='p' sx={{fontSize: '1rem !important',color: 'greenyellow !important'}}>+LVL UP <div style={{backgroundSize: '100%', width: '30px', height: '30px'}} class="rpgui-icon exclamation"></div> </Typography>
     {
      Object.entries(tierras.find(i=> i.id === selector.id).nivel[selector?.nivel - 1]).map(i=><Typography key={v4()} variant='p' sx={{fontSize: '0.7rem !important', textAlign: 'start', color: 'white !important'}}>{`${i[0]}: ${i[1]}`}</Typography>)
  
     }
     
     
      
     <SubirNivelButton setError={setError} setMensaje={setMensaje} setOpen={setOpen} selector={selector}/>
           </Box>
           </Box>

  </Box>
  <Box  class="rpgui-container framed-golden-m" sx={{flexGrow: "2 !important",position: 'relative !important',ml: 1,width:{xs: '100% !important', md:'50% !important'}, height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}  >
      <Typography variant='p' sx={{display: 'block',fontSize: '1.5rem !important', textAlign: 'center !important'}}>Oak Tree</Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'start', width:'100%', height: '100%', flexDirection: 'column'}}>
      <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>

      <CardMedia
                 className="rpgui-cursor-grab-open"
           component="img"
           image={agua}
           sx={{ width: "70px", objectFit: "cover" }}
         />
             </Box>
      <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>LVL: {selector?.nivel}</Typography>
      <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>Production: 5 Wood x lvl every 72 hours</Typography>
      <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>trees for planting: {selector?.nivel}</Typography>
      <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>Requirements:  { selector?.costo * parseInt(semillas)} Water </Typography>
     {selector?.tiempo !== '' && <Typography  variant='p' sx={{fontSize: '1rem !important', textAlign: 'center'}}>harvest:{ 
    selector?.semillas
     
     }</Typography>}
{
    selector?.tiempo === '' && <select value={semillas} onChange={handleChange} class="rpgui-dropdown ">
	<option value="1">1 - tree</option>
	<option value="2">2 - tree</option>
	<option value="3">3 - tree</option>
	<option value="4">4 - tree</option>

</select>}

    
     <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml:1}}>
         {selector?.tiempo === '' && <Button class="rpgui-button" onClick={plantarSemilla}  ><p>Tree Planting </p></Button>}
         { dayjs().utc().diff(selector?.tiempo, 'second') > 0 && <Button class="rpgui-button" onClick={recolectarSemilla}  ><p>harvest</p></Button>}
         {/* <Button class="rpgui-button"  ><p>collect</p></Button>
         <Button class="rpgui-button"  ><p>    plant seeds</p></Button> */}
       </Box>
       { selector?.tiempo !== "" && <Contador segundos={ selector?.tiempo} />}
      </Box>
     <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />
  </Box>
  </Box>
  </Box>


   
  )
}
